import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { IFetchComponentResponse } from 'components/domain/fdr-page-components/fdr-page-components.types';
import { FdrProductRecommenderSlider } from 'components/domain/fdr-product-recommender';
import { DEFAULTS_RESULT_PER_PAGE } from 'components/domain/fdr-search/fdr-search.constants';
import useLazyFetchPagination, {
  ILazyPaginationStaticItem,
} from 'components/domain/pagination/useLazyFetchPagination';
import CommonGridRecommendationSection from 'components/domain/recommendations/CommonGridRecommendationSection';
import { GridRecommendationItems } from 'components/domain/recommendations/CommonGridRecommendationSection.styles';
import ProductRecommendationCustomContent from 'components/domain/recommendations/ProductRecommendationsSectionCustomContent';
import {
  PRODUCT_GRID_ITEMS_PER_PAGE,
  ProductRecommendationView,
} from 'components/domain/recommendations/Recommendations.constants';
import { FeatureList } from 'config/featureList';
import {
  FdrProductRecommendation,
  TFdrProductRecommendationProps,
} from './fdr-product-recommendation';
import { FdrProductRecommenderStaticItem } from './fdr-product-recommender-static-item';
import {
  EFdrProductRecommenderItemType,
  IFdrProductRecommenderProduct,
  IFdrProductRecommenderComponent,
  TFdrRecommenderProductItem,
} from './fdr-product-recommender.types';

interface IFdrProductRecommenderProps {
  component: IFdrProductRecommenderComponent;
  id?: string;
  loadMoreText?: string;
  view?: ProductRecommendationView;
  testId?: string;
  sectionHeaderTitle?: string;
  fetchItems?(
    page: number,
    perPage: number
  ): Promise<IFetchComponentResponse<IFdrProductRecommenderProduct>>;
  loading?: boolean;
  sliderRecommendationItem?: React.FC<
    PropsWithChildren<TFdrProductRecommendationProps>
  >;
  gridRecommendationItem?: React.FC<
    PropsWithChildren<{
      item: IFdrProductRecommenderProduct;
    }>
  >;
}

export function FdrProductRecommender({
  component,
  id,
  loadMoreText,
  view = ProductRecommendationView.SLIDER,
  testId,
  children,
  sectionHeaderTitle,
  fetchItems,
  loading,
  sliderRecommendationItem: SliderRecommendationItem,
  gridRecommendationItem: GridRecommendationItem,
}: PropsWithChildren<IFdrProductRecommenderProps>): ReactElement {
  const { mobile } = useDeviceType();
  const perPage =
    view === ProductRecommendationView.SLIDER
      ? DEFAULTS_RESULT_PER_PAGE
      : PRODUCT_GRID_ITEMS_PER_PAGE;

  const { getVariant } = useFeatureToggle();
  const variant = getVariant(FeatureList.prodRecommenderFakeItem);
  const isStaticItemEnabled =
    variant?.enabled && variant.name === STATIC_ITEM_VARIANT_NAME;

  const staticItems: ILazyPaginationStaticItem<TFdrRecommenderProductItem>[] =
    useMemo(() => {
      if (
        !isStaticItemEnabled ||
        !component?.total ||
        view !== ProductRecommendationView.SLIDER
      )
        return [];

      const position = mobile
        ? MOBILE_STATIC_ITEM_POSITION
        : component.total < DESKTOP_STATIC_ITEM_POSITION + 1
        ? component.total
        : DESKTOP_STATIC_ITEM_POSITION;

      return [
        {
          position,
          item: { type: EFdrProductRecommenderItemType.STATIC },
        },
      ];
    }, [isStaticItemEnabled, component?.total, mobile, view]);

  const mappedItems: TFdrRecommenderProductItem[] = useMemo(() => {
    return (
      component?.items?.map(item => ({
        type: EFdrProductRecommenderItemType.PRODUCT,
        item,
      })) || []
    );
  }, [component?.items]);

  const fetchedMappedItems = useCallback(
    async (
      targetPage: number,
      targetPerPage: number
    ): Promise<IFetchComponentResponse<TFdrRecommenderProductItem>> => {
      const response = await fetchItems(targetPage, targetPerPage);

      return {
        ...response,
        items:
          response?.items?.map(item => ({
            type: EFdrProductRecommenderItemType.PRODUCT,
            item,
          })) || [],
      };
    },
    [fetchItems]
  );

  const { items, page, nextPage, prevPage, setPage, hasMore } =
    useLazyFetchPagination<TFdrRecommenderProductItem>(mappedItems, {
      perPage,
      total: component.total + staticItems.length,
      fetchItems: fetchedMappedItems,
      staticItems,
    });

  const [imageHeight, setImageHeight] = useState<number>();

  const updateImageHeight = useCallback((height: number) => {
    if (height) {
      setImageHeight(height);
    }
  }, []);

  const SliderItemVariant =
    SliderRecommendationItem ?? FdrProductRecommendation;

  if (view === ProductRecommendationView.SLIDER) {
    return (
      <FdrProductRecommenderSlider
        id={id}
        testId={testId}
        sectionTitle={sectionHeaderTitle}
        customContent={
          <ProductRecommendationCustomContent>
            {children}
          </ProductRecommendationCustomContent>
        }
        {...component}
        total={component.total + staticItems.length}
        page={page}
        perPage={perPage}
        prevPage={prevPage}
        nextPage={nextPage}
        setPage={setPage}
        loading={loading}
        imageHeight={imageHeight}
        isSliderPagination={!mobile}
      >
        {items.map((item, index) => (
          <>
            {item.type === EFdrProductRecommenderItemType.STATIC && (
              <FdrProductRecommenderStaticItem
                key={`static-slider-item-${index}`}
              ></FdrProductRecommenderStaticItem>
            )}
            {item.type === EFdrProductRecommenderItemType.PRODUCT && (
              <SliderItemVariant
                key={`product-slider-item-${item.item.id}`}
                {...item.item}
                lazy={index + 1 > perPage}
                updateImageHeight={updateImageHeight}
              />
            )}
          </>
        ))}
      </FdrProductRecommenderSlider>
    );
  }

  const GridItemVariant =
    GridRecommendationItem ?? ProductRecommendationGridItem;

  return (
    <CommonGridRecommendationSection
      id={id}
      nextPage={nextPage}
      hasMore={hasMore}
      loading={loading}
      mainTitle={component.name}
      loadMoreText={loadMoreText}
      testId={testId}
    >
      {children}
      <GridRecommendationItems>
        {items.map(item => {
          if (item.type === EFdrProductRecommenderItemType.PRODUCT) {
            return (
              <GridItemVariant
                item={item.item}
                key={`product-grid-item-${item.item.id}`}
              />
            );
          }
        })}
      </GridRecommendationItems>
    </CommonGridRecommendationSection>
  );
}

const STATIC_ITEM_VARIANT_NAME = 'new-design';
const MOBILE_STATIC_ITEM_POSITION = 1;
const DESKTOP_STATIC_ITEM_POSITION = 4;

const ProductRecommendationGridItem: React.FC<{
  item: IFdrProductRecommenderProduct;
}> = ({ item }) => {
  return <FdrProductRecommendation key={item.link.url} {...item} />;
};
