import React, { PropsWithChildren, ReactElement } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { RecommendationListWithSlidesNavigationContainer } from 'components/domain/fdr-page-components/recommenders/product/fdr-product-recommendation.styles';
import { IFdrProductRecommenderComponent } from 'components/domain/fdr-page-components/recommenders/product/fdr-product-recommender.types';
import { RecommendationListContainer } from 'components/domain/recommendations/Recommendations.styles';
import FdrProductRecommenderSliderContent from './fdr-product-recommender-slider-content';

type TFdrProductRecommenderContainerProps = Omit<
  IFdrProductRecommenderComponent,
  'items'
> & {
  id: string;
  page: number;
  perPage: number;
  nextPage(): void;
  prevPage(): void;
  setPage(page: number): void;
  sectionTitle?: string;
  loading?: boolean;
  testId?: string;
  customContent?: React.ReactNode;
  imageHeight?: number;
  isSliderPagination?: boolean;
};

export function FdrProductRecommenderSlider({
  id,
  testId,
  fontColor,
  backgroundColor,
  children,
  ...sliderProps
}: PropsWithChildren<TFdrProductRecommenderContainerProps>): ReactElement {
  const { mobile } = useDeviceType();

  const ContainerVariant = !mobile
    ? RecommendationListWithSlidesNavigationContainer
    : RecommendationListContainer;

  return (
    <div data-id={testId} id={id} style={{ color: fontColor, backgroundColor }}>
      <ContainerVariant>
        <FdrProductRecommenderSliderContent {...sliderProps}>
          {children}
        </FdrProductRecommenderSliderContent>
      </ContainerVariant>
    </div>
  );
}
