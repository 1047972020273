import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { FdrDisrupterFragmentDoc } from 'schemas/fragment/disrupter/fdr-disrupter.generated';
import {
  FdrProductFeatureGroupFragmentDoc,
  FdrB2BInfoGroupsFragmentDoc,
} from 'schemas/fragment/b2b-info-groups/fdr-b2b-info-groups.generated';
import { FdrAdventureTravelRouteFragmentDoc } from 'schemas/fragment/product/fdr-adventure-travel-route.generated';
export type FdrProductRecommenderFragment = {
  __typename: 'FdrProductRecommender';
  id: string;
  title?: string | null;
  fgColor?: string | null;
  bgColor?: string | null;
  productPage: {
    __typename?: 'FdrProductRecommenderItems';
    products?: Array<
      | {
          __typename: 'FdrAdventureTravel';
          name: string;
          teaser: string;
          id: string;
          bookmarked: boolean;
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
            bgColor?: string | null;
            fgColor?: string | null;
          }> | null;
          route?: Array<{
            __typename?: 'FdrAdventureTravelRoutePoint';
            locationName: string;
            coordinates: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            };
          }> | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          featureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
        }
      | { __typename: 'FdrCruise' }
      | {
          __typename: 'FdrHotel';
          name: string;
          id: string;
          bookmarked: boolean;
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
            bgColor?: string | null;
            fgColor?: string | null;
          }> | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        }
      | {
          __typename: 'FdrRoundTrip';
          name: string;
          mode?: string | null;
          giataCode?: number | null;
          leadText?: string | null;
          duration: string;
          id: string;
          bookmarked: boolean;
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
            bgColor?: string | null;
            fgColor?: string | null;
          }> | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          featureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
        }
    > | null;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
  };
};

export type FdrProductRecommenderItemFdrAdventureTravelFragment = {
  __typename: 'FdrAdventureTravel';
  name: string;
  teaser: string;
  id: string;
  bookmarked: boolean;
  images?: Array<{
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  }> | null;
  disrupters?: Array<{
    __typename?: 'FdrDisrupter';
    text: string;
    bgColor?: string | null;
    fgColor?: string | null;
  }> | null;
  route?: Array<{
    __typename?: 'FdrAdventureTravelRoutePoint';
    locationName: string;
    coordinates: {
      __typename?: 'FdrCoordinates';
      latitude: number;
      longitude: number;
    };
  }> | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  featureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
};

export type FdrProductRecommenderItemFdrCruiseFragment = {
  __typename: 'FdrCruise';
};

export type FdrProductRecommenderItemFdrHotelFragment = {
  __typename: 'FdrHotel';
  name: string;
  id: string;
  bookmarked: boolean;
  images?: Array<{
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  }> | null;
  disrupters?: Array<{
    __typename?: 'FdrDisrupter';
    text: string;
    bgColor?: string | null;
    fgColor?: string | null;
  }> | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrProductRecommenderItemFdrRoundTripFragment = {
  __typename: 'FdrRoundTrip';
  name: string;
  mode?: string | null;
  giataCode?: number | null;
  leadText?: string | null;
  duration: string;
  id: string;
  bookmarked: boolean;
  images?: Array<{
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  }> | null;
  disrupters?: Array<{
    __typename?: 'FdrDisrupter';
    text: string;
    bgColor?: string | null;
    fgColor?: string | null;
  }> | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  featureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
};

export type FdrProductRecommenderItemFragment =
  | FdrProductRecommenderItemFdrAdventureTravelFragment
  | FdrProductRecommenderItemFdrCruiseFragment
  | FdrProductRecommenderItemFdrHotelFragment
  | FdrProductRecommenderItemFdrRoundTripFragment;

export type FdrProductRecommenderRoundTripFragment = {
  __typename?: 'FdrRoundTrip';
  name: string;
  mode?: string | null;
  giataCode?: number | null;
  leadText?: string | null;
  duration: string;
  id: string;
  bookmarked: boolean;
  images?: Array<{
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  }> | null;
  disrupters?: Array<{
    __typename?: 'FdrDisrupter';
    text: string;
    bgColor?: string | null;
    fgColor?: string | null;
  }> | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  featureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
};

export type FdrProductRecommenderAdventureTravelFragment = {
  __typename?: 'FdrAdventureTravel';
  name: string;
  teaser: string;
  id: string;
  bookmarked: boolean;
  images?: Array<{
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  }> | null;
  disrupters?: Array<{
    __typename?: 'FdrDisrupter';
    text: string;
    bgColor?: string | null;
    fgColor?: string | null;
  }> | null;
  route?: Array<{
    __typename?: 'FdrAdventureTravelRoutePoint';
    locationName: string;
    coordinates: {
      __typename?: 'FdrCoordinates';
      latitude: number;
      longitude: number;
    };
  }> | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  featureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
};

export type FdrProductRecommenderHotelFragment = {
  __typename?: 'FdrHotel';
  name: string;
  id: string;
  bookmarked: boolean;
  images?: Array<{
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  }> | null;
  disrupters?: Array<{
    __typename?: 'FdrDisrupter';
    text: string;
    bgColor?: string | null;
    fgColor?: string | null;
  }> | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export const FdrProductRecommenderRoundTripFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommenderRoundTrip' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRoundTrip' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giataCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'bookmarked' },
            name: { kind: 'Name', value: 'isInBookmarks' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '468' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '388' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '936' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '776' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '380' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '274' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '760' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '548' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrDisrupter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'descriptionWebMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductFeatureGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrDisrupterFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
    ...FdrProductFeatureGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductRecommenderAdventureTravelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommenderAdventureTravel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAdventureTravel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teaser' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'bookmarked' },
            name: { kind: 'Name', value: 'isInBookmarks' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'images' },
            name: { kind: 'Name', value: 'headerImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '468' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '388' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '936' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '776' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '380' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '274' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '760' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '548' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrDisrupter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAdventureTravelRoute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'descriptionWebMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductFeatureGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrDisrupterFragmentDoc.definitions,
    ...FdrAdventureTravelRouteFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
    ...FdrProductFeatureGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductRecommenderHotelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommenderHotel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHotel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'bookmarked' },
            name: { kind: 'Name', value: 'isInBookmarks' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '468' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '388' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '936' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '776' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '380' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '274' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '760' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '548' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrDisrupter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'descriptionWebMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrDisrupterFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductRecommenderItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommenderItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProduct' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrRoundTrip' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrProductRecommenderRoundTrip',
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAdventureTravel' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrProductRecommenderAdventureTravel',
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrHotel' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductRecommenderHotel' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductRecommenderRoundTripFragmentDoc.definitions,
    ...FdrProductRecommenderAdventureTravelFragmentDoc.definitions,
    ...FdrProductRecommenderHotelFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductRecommenderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommender' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'productPage' },
            name: { kind: 'Name', value: 'recommenderItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '9' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderItem',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductRecommenderItemFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
