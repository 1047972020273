import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrProductRecommenderSliderStaticItemWrapper = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'paperGrey',
    ':after': {
      content: "''",
      display: 'block',
      width: '100%',
      paddingBottom: '83%',
      pointerEvents: 'none',
    },
  })
);

export const FdrProductRecommenderSliderStaticItemContent = styled.div(
  sx2CssThemeFn({
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    padding: ['10px 14px 10px', '20px 56px 20px'],
    position: 'absolute',
    left: 0,
    top: 0,
    overflow: 'hidden',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export const FdrProductRecommenderSliderStaticItemTitle = styled.div(
  sx2CssThemeFn({
    fontSize: 4,
    fontWeight: 800,
  })
);

export const FdrProductRecommenderSliderStaticItemText = styled.div(
  sx2CssThemeFn({
    marginTop: '8px',
    fontSize: 1,
    lineHeight: 1.4,
  })
);

export const FdrProductRecommenderSliderStaticItemButton = styled.div({
  marginTop: '16px',
});
