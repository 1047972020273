import React from 'react';
import { mapCoordinatesToLatLngLiteral } from '@hotelplan/components.common.map-pin';
import { TMediaMetaMap } from '@hotelplan/core.basis.bs-picture';
import { TRenderMediaItem } from '@hotelplan/core.serving.bs-rsv-gallery/gallery-slider.types';
import { TCustomMapControl } from '@hotelplan/fdr.regular.fusion.fdr-map';
import {
  FdrAdventureTravelRoutePoint,
  FdrImageResizeMode,
} from '@hotelplan/supergraph-api';
import { ProductPinType } from 'components/domain/fdr-adventure-travel-routes';
import {
  MapClickEventTargetType,
  trackMapClick,
} from 'components/domain/tracking/pdpTracking';
import { ClickEventActionType } from 'components/domain/tracking/tracking.types';
import photoSwitchMap from 'public/images/svg/photo-switch-map.svg';
import routeSwitchMap from 'public/images/svg/route-switch-map.svg';
import FdrGalleryWithMapMediaItem from './fdr-gallery-with-map-media-item';

enum MapPinControlType {
  pins = 'PINS',
  routePoints = 'ROUTE_POINTS',
}

const mapPinControlTypeToIcon = new Map<MapPinControlType, string>([
  [MapPinControlType.pins, photoSwitchMap],
  [MapPinControlType.routePoints, routeSwitchMap],
]);

const productPinTypeToMapPinControlType = new Map<
  ProductPinType,
  MapPinControlType
>([
  [ProductPinType.PinIcon, MapPinControlType.pins],
  [ProductPinType.RouteIcon, MapPinControlType.routePoints],
]);

export const getPath = (routePoints: Array<FdrAdventureTravelRoutePoint>) => {
  return routePoints.map(point => {
    return mapCoordinatesToLatLngLiteral(point.coordinates);
  });
};

function getControlType(pinType: ProductPinType): MapPinControlType {
  return productPinTypeToMapPinControlType.get(pinType);
}

function getControlIcon(controlType: MapPinControlType): string {
  return mapPinControlTypeToIcon.get(controlType);
}

const mapPinControlList = [ProductPinType.PinIcon, ProductPinType.RouteIcon];

function getNextPinType(currentPinType: ProductPinType): ProductPinType {
  const currentIndex = mapPinControlList.indexOf(currentPinType);
  let nextIndex = 0;

  if (currentIndex + 1 < mapPinControlList.length) {
    nextIndex = currentIndex + 1;
  }

  return mapPinControlList[nextIndex];
}

interface IMapPinControlOptions {
  pinType: ProductPinType;
  onChange?(type: ProductPinType): void;
  captionGallery: string;
  captionRouting: string;
  isPdp: boolean;
}

export function createDefaultMapPinControl({
  pinType,
  onChange,
  captionGallery,
  captionRouting,
  isPdp,
}: IMapPinControlOptions): TCustomMapControl {
  if (process.browser) {
    const mapPinControl = document.createElement('div');
    const controlUI = document.createElement('div');
    const controlIcon = document.createElement('img');
    const controlIconCaption = document.createElement('div');
    const iconCaption = document.createElement('p');

    let currentPinType = pinType;

    const nextPinType = getNextPinType(currentPinType);

    controlUI.classList.add(`map-control-button`);
    controlIconCaption.classList.add(`map-control-caption`);

    controlIcon.src = getControlIcon(getControlType(nextPinType));

    iconCaption.textContent =
      pinType === ProductPinType.PinIcon ? captionRouting : captionGallery;

    const handleClick = () => {
      const newPinType = getNextPinType(currentPinType);
      const newNextPinType = getNextPinType(newPinType);

      controlIcon.src = getControlIcon(getControlType(newNextPinType));

      currentPinType = newPinType;
      onChange(newPinType);

      const isPins = newPinType === ProductPinType.PinIcon;

      if (isPdp) {
        trackMapClick(
          ClickEventActionType.SWITCH,
          isPins
            ? MapClickEventTargetType.MAP_PDP_IMAGE_VIEW
            : MapClickEventTargetType.MAP_PDP_ROUTING_VIEW
        );
      }

      iconCaption.textContent = isPins ? captionRouting : captionGallery;
    };

    controlUI.addEventListener('click', handleClick);

    controlIconCaption.appendChild(iconCaption);
    controlUI.appendChild(controlIcon);
    controlUI.appendChild(controlIconCaption);
    mapPinControl.appendChild(controlUI);

    return {
      element: mapPinControl,
      cleanup: () => controlUI.removeEventListener('click', handleClick),
    };
  }
}

export const renderGalleryWithMapSlide = (
  previewSpec: TMediaMetaMap<FdrImageResizeMode>,
  fullSpec: TMediaMetaMap<FdrImageResizeMode>,
  total: number
): TRenderMediaItem => {
  return function GalleryWithMapSlideRender(item, i, onSlideClick, preview) {
    return (
      <FdrGalleryWithMapMediaItem
        key={i}
        item={item}
        index={i}
        total={total}
        lazy={i > 0}
        preview={preview}
        onClick={onSlideClick}
        fullSpec={fullSpec}
        previewSpec={previewSpec}
      />
    );
  };
};
