import { FieldMergeFunction } from '@apollo/client';
import {
  fdrMergePage,
  fdrMergePaginatedItems,
} from '@hotelplan/fdr.lib.apollo.policies';
import {
  FdrAgencySuggestion,
  FdrAllBlogArticlesResponse,
  FdrBlogArticle,
  FdrCountry,
  FdrCountrySiblings,
  FdrRegion,
  FdrRegionSiblings,
  FdrSearchAgenciesResponse,
  FdrSearchHistory,
  FdrSearchHistoryResponse,
  FdrVisitHistory,
  FdrVisitHistoryResponse,
} from '@hotelplan/supergraph-api';

export const fdrBlogArticlesMerge: FieldMergeFunction<FdrAllBlogArticlesResponse> =
  (existing, incoming) => {
    if (!existing) return incoming;

    const articles = fdrMergePaginatedItems<FdrBlogArticle>(
      incoming.pagination,
      undefined,
      existing.blogArticles,
      incoming.blogArticles
    );

    const mergedPagination = fdrMergePage(
      existing.pagination,
      incoming.pagination,
      articles.length
    );

    return {
      ...incoming,
      blogArticles: articles,
      pagination: mergedPagination,
    };
  };

export const fdrAgenciesAutocompleteMerge: FieldMergeFunction<FdrSearchAgenciesResponse> =
  (existing, incoming) => {
    if (!existing) return incoming;

    const mergedAgencies = fdrMergePaginatedItems<FdrAgencySuggestion>(
      incoming.pagination,
      undefined,
      existing.agencies,
      incoming.agencies
    );

    const mergedPagination = fdrMergePage(
      existing.pagination,
      incoming.pagination,
      mergedAgencies.length
    );

    return {
      ...incoming,
      agencies: mergedAgencies,
      pagination: mergedPagination,
    };
  };

export const fdrLastSearchedMerge: FieldMergeFunction<FdrSearchHistoryResponse> =
  (existing, incoming) => {
    if (!existing) return incoming;

    const mergedSearchedItems = fdrMergePaginatedItems<FdrSearchHistory>(
      incoming.pagination,
      undefined,
      existing.entries,
      incoming.entries
    );

    const mergedPagination = fdrMergePage(
      existing.pagination,
      incoming.pagination,
      mergedSearchedItems.length
    );

    return {
      ...incoming,
      entries: mergedSearchedItems,
      pagination: mergedPagination,
    };
  };

export const fdrLastViewedMerge: FieldMergeFunction<FdrVisitHistoryResponse> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;

  const mergedViewedItems = fdrMergePaginatedItems<FdrVisitHistory>(
    incoming.pagination,
    undefined,
    existing.entries,
    incoming.entries
  );

  const mergedPagination = fdrMergePage(
    existing.pagination,
    incoming.pagination,
    mergedViewedItems.length
  );

  return {
    ...incoming,
    entries: mergedViewedItems,
    pagination: mergedPagination,
  };
};

export const fdrMergeGeoSiblings: FieldMergeFunction<
  FdrCountrySiblings | FdrRegionSiblings
> = (existing, incoming) => {
  if (!existing) return incoming;

  let mergedItems: FdrCountry[] | FdrRegion[];

  switch (incoming?.__typename) {
    case 'FdrCountrySiblings':
      mergedItems = fdrMergePaginatedItems<FdrCountry>(
        incoming.pagination,
        existing.pagination,
        (existing as FdrCountrySiblings).countryPage,
        incoming.countryPage
      );

      return {
        ...incoming,
        countryPage: mergedItems,
        pagination: fdrMergePage(
          existing.pagination,
          incoming.pagination,
          mergedItems.length
        ),
      };

    case 'FdrRegionSiblings':
      mergedItems = fdrMergePaginatedItems<FdrRegion>(
        incoming.pagination,
        existing.pagination,
        (existing as FdrRegionSiblings).regionsPage,
        incoming.regionsPage
      );

      return {
        ...incoming,
        regionsPage: mergedItems,
        pagination: fdrMergePage(
          existing.pagination,
          incoming.pagination,
          mergedItems.length
        ),
      };

    default:
      return incoming;
  }
};
