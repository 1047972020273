import React, { useCallback } from 'react';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToBlogArticles,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { fdrBlogCriteria } from 'components/util/criterias';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrBlogArticleComponentsDocument } from 'schemas/query/blog-article/fdr-paginated-blog-recommender.generated';
import BlogArticleRecommendationsSection from './fdr-blog-recommender';

interface IFdrBlogPageComponent {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrBlogArticleRecommender' }
  >;
}

const FdrBlogPageComponent: React.FC<IFdrBlogPageComponent> = ({
  component,
}) => {
  const mapVariables = useCallback(
    (page, perPage) => {
      return {
        ...createPageComponentVariables(component.id, page, perPage),
        blogCriteria: fdrBlogCriteria,
      };
    },
    [component.id]
  );

  const { fetchItems } = useFdrPageComponentsFetch(
    FdrBlogArticleComponentsDocument,
    mapVariables,
    mapFdrPageComponentsResponseToBlogArticles
  );

  return (
    <BlogArticleRecommendationsSection
      component={component}
      fetchItems={fetchItems}
    />
  );
};

export default FdrBlogPageComponent;
