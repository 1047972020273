import React from 'react';
import styled from 'styled-components';
import {
  IMediaGalleryItem,
  SlideDescription,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import ResponsiveImgWithCopyright from 'components/domain/copyright/responsive-img-with-copyright';
import {
  DefaultMediaGalleryItem,
  TDefaultMediaItemRenderProps,
} from 'components/domain/default-media-item/default-media-item-render.utils';

const FdrGalleryWithMapMediaItemDescription = styled(SlideDescription)(
  sx2CssThemeFn({
    pl: [3, 4],
    pr: '55px',
    p: {
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

type TFdrGalleryWithMapMediaItemProps<T extends IMediaGalleryItem> =
  TDefaultMediaItemRenderProps<T> & {
    index: number;
    total: number;
  };

const FdrGalleryWithMapMediaItem = <T extends IMediaGalleryItem>({
  item,
  index,
  total,
  lazy,
  onClick,
  preview,
  previewSpec,
  fullSpec,
}: TFdrGalleryWithMapMediaItemProps<T>) => {
  const image = preview ? item.preview || item.image : item.image;
  const specs = preview ? previewSpec : fullSpec;
  const imageProps = {
    className: 'image',
    lazy,
    onClick(e) {
      onClick(item, e);
    },
  };

  return (
    <DefaultMediaGalleryItem
      lazy={lazy}
      className="general-item"
      isGallery
      {...item}
    >
      <figure>
        <ResponsiveImgWithCopyright
          image={image}
          specs={specs}
          imageProps={imageProps}
        />
        {preview && (
          <figcaption>
            <FdrGalleryWithMapMediaItemDescription>
              <p>
                <span className="slide-number">
                  {index + 1} | {total}
                </span>
                {item.title && (
                  <span className="slide-title">{item.title}</span>
                )}
              </p>
            </FdrGalleryWithMapMediaItemDescription>
          </figcaption>
        )}
      </figure>
    </DefaultMediaGalleryItem>
  );
};

export default FdrGalleryWithMapMediaItem;
