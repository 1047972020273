import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrProductRecommenderItemFragmentDoc } from 'schemas/fragment/recommenders/product/fdr-product-recommender.generated';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrProductComponentsQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  pagination: Types.FdrPaginationInput;
}>;

export type FdrProductComponentsQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename?: 'FdrGeoOfferRecommender' }
      | { __typename?: 'FdrGeoRecommender' }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | { __typename?: 'FdrProductOfferRecommender' }
      | { __typename?: 'FdrProductOffersMap' }
      | {
          __typename: 'FdrProductRecommender';
          id: string;
          title?: string | null;
          fgColor?: string | null;
          bgColor?: string | null;
          productPage: {
            __typename?: 'FdrProductRecommenderItems';
            products?: Array<
              | {
                  __typename: 'FdrAdventureTravel';
                  name: string;
                  teaser: string;
                  id: string;
                  bookmarked: boolean;
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                    bgColor?: string | null;
                    fgColor?: string | null;
                  }> | null;
                  route?: Array<{
                    __typename?: 'FdrAdventureTravelRoutePoint';
                    locationName: string;
                    coordinates: {
                      __typename?: 'FdrCoordinates';
                      latitude: number;
                      longitude: number;
                    };
                  }> | null;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      id: string;
                      name: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                }
              | { __typename: 'FdrCruise' }
              | {
                  __typename: 'FdrHotel';
                  name: string;
                  id: string;
                  bookmarked: boolean;
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                    bgColor?: string | null;
                    fgColor?: string | null;
                  }> | null;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | {
                  __typename: 'FdrRoundTrip';
                  name: string;
                  mode?: string | null;
                  giataCode?: number | null;
                  leadText?: string | null;
                  duration: string;
                  id: string;
                  bookmarked: boolean;
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                    bgColor?: string | null;
                    fgColor?: string | null;
                  }> | null;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      id: string;
                      name: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                }
            > | null;
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
          };
        }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrRssBlogArticleRecommender' }
      | { __typename?: 'FdrSmartSeerRecommender' }
      | { __typename?: 'FdrSrlButton' }
      | { __typename?: 'FdrThemeGeoRecommender' }
      | { __typename?: 'FdrThemeOfferRecommender' }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export type FdrPaginatedProductRecommenderFragment = {
  __typename: 'FdrProductRecommender';
  id: string;
  title?: string | null;
  fgColor?: string | null;
  bgColor?: string | null;
  productPage: {
    __typename?: 'FdrProductRecommenderItems';
    products?: Array<
      | {
          __typename: 'FdrAdventureTravel';
          name: string;
          teaser: string;
          id: string;
          bookmarked: boolean;
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
            bgColor?: string | null;
            fgColor?: string | null;
          }> | null;
          route?: Array<{
            __typename?: 'FdrAdventureTravelRoutePoint';
            locationName: string;
            coordinates: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            };
          }> | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          featureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
        }
      | { __typename: 'FdrCruise' }
      | {
          __typename: 'FdrHotel';
          name: string;
          id: string;
          bookmarked: boolean;
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
            bgColor?: string | null;
            fgColor?: string | null;
          }> | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        }
      | {
          __typename: 'FdrRoundTrip';
          name: string;
          mode?: string | null;
          giataCode?: number | null;
          leadText?: string | null;
          duration: string;
          id: string;
          bookmarked: boolean;
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
            bgColor?: string | null;
            fgColor?: string | null;
          }> | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          featureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
        }
    > | null;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
  };
};

export const FdrPaginatedProductRecommenderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrPaginatedProductRecommender' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'productPage' },
            name: { kind: 'Name', value: 'recommenderItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderItem',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductRecommenderItemFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductComponentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrProductComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'components' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrPaginatedProductRecommender',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPaginatedProductRecommenderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrProductComponentsQuery__
 *
 * To run a query within a React component, call `useFdrProductComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrProductComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrProductComponentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFdrProductComponentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrProductComponentsQuery,
    FdrProductComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrProductComponentsQuery,
    FdrProductComponentsQueryVariables
  >(FdrProductComponentsDocument, options);
}
export function useFdrProductComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrProductComponentsQuery,
    FdrProductComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrProductComponentsQuery,
    FdrProductComponentsQueryVariables
  >(FdrProductComponentsDocument, options);
}
export type FdrProductComponentsQueryHookResult = ReturnType<
  typeof useFdrProductComponentsQuery
>;
export type FdrProductComponentsLazyQueryHookResult = ReturnType<
  typeof useFdrProductComponentsLazyQuery
>;
export type FdrProductComponentsQueryResult = Apollo.QueryResult<
  FdrProductComponentsQuery,
  FdrProductComponentsQueryVariables
>;
