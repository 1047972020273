import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import SkeletonBaseBlock from 'components/domain/skeleton/SkeletonBaseBlock';
import {
  Container,
  SlidersNavigationContainer,
} from 'components/domain/container';
import { BaseInput } from '@hotelplan/components.common.inputs';
import {
  FdrSearchButton,
  FdrSearchResetButton,
} from 'components/domain/fdr-search-overlay';
import {
  AdaptiveCardsSlider,
  CardsSection,
} from 'components/domain/customizable-slider/CardsSection';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrTeaserCard } from 'components/domain/fdr-teaser-card';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { FdrScheduleOpeningStatus } from 'components/candidate/fdr-schedule';

export const FdrSearchPageHeader = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    pt: '40px',
    flexDirection: [`column-reverse`, 'row'],
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '.exact-search': {
      mb: [4, 0],
    },
  })
);

export const FdrSearchPageHeading = styled.h1(
  ({ theme: { text } }) => text.searchHeading
);

export const FdrSearchPageHeadingSkeleton = styled(SkeletonBaseBlock).attrs({
  height: '65px',
  width: '100%',
  borderRadius: '6px',
})({
  marginTop: '16px',
});

export const FdrSearchPageContainer = styled(Container)(
  ({ theme: { media, space } }) => ({
    paddingTop: '214px',
    overflow: 'hidden',
    [media.mobile]: {
      overflow: 'visible',
      paddingTop: '180px',
    },
    '.load-more-wrapper': {
      margin: '-20px auto',
      marginBottom: space[7],
    },
  })
);

export const FdrEmptySearchButton = styled(FdrSearchResetButton)(
  sx2CssThemeFn({
    svg: {
      color: 'primary',
    },
  })
);

export const FdrSearchFieldWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    display: 'flex',
    justifyContent: ['flex-start'],
  })
);

export const FdrSearchInputWrap = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: ['100%', 'auto'],
  })
);

export const FdrSearchInput = styled(BaseInput)(
  ({ theme }) => theme.inputs.searchInput,
  () =>
    sx2CssThemeFn({
      flex: 1,
      '.input': {
        width: ['100%', '568px'],
        borderRadius: '8px 0 0 8px',
        borderColor: 'secondary',
      },
      '.input-icon': {
        color: 'primary',
        fill: 'transparent',
      },
    })
);

export const FdrSRLSearchButton = styled(FdrSearchButton)(
  ({ theme: { media } }) => ({
    [media.mobile]: {
      width: '56px',
      flexShrink: 0,
      '.icon': {
        marginTop: '6px',
      },
    },
  })
);

export const FdrSearchSectionWithSlidersNavigationContainer = styled(
  SlidersNavigationContainer
)(
  sx2CssThemeFn({
    pb: 4,
    overflow: ['visible', 'hidden'],
  })
);

export const FdrSearchSectionContainer = styled(FdrSearchPageContainer)(
  sx2CssThemeFn({
    pb: 4,
    overflow: ['visible', 'hidden'],
    pt: 0,
  })
);

export const FdrSearchCardsSection = styled(CardsSection)(
  sx2CssThemeFn({
    position: 'relative',
    mb: '20px',
    pt: '40px',
    '.media-card-content': {
      color: 'white',
    },
  })
);

export const FdrCardsSlider = styled(AdaptiveCardsSlider)(
  sx2CssThemeFn({
    mx: [-4, 0],
    pl: [4, 0],
  })
);

export const FdrSearchItemWrapper = styled.div({
  position: 'relative',
  '.wishlist-icon-button': {
    position: 'absolute',
    right: '16px',
    top: '16px',
    cursor: 'pointer',
    zIndex: 5,
    '.wishlist-icon': {
      width: '26px',
      height: '26px',
      color: 'white',
    },
    '.button-text': {
      display: 'none',
    },
  },
});

export const FdrTeaserLink = styled(FdrRoute)({
  position: 'relative',
  height: '100%',
  width: '100%',
  '&:hover': {
    color: 'inherit',
  },
});

export const FdrTeaserImageCardWrapper = styled(FdrTeaserCard)(
  ({ theme: { colors, space, fontSizes } }) => ({
    color: 'white',
    '.debug-info': {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '5px 15px',
      borderRadius: '5px',
      backgroundColor: 'rgba(114, 119, 113, 0.4)',
      width: '100%',
      '&:hover': {
        fontSize: fontSizes[2],
        backgroundColor: colors.redHover,
        // margin: '5px 10px',
        transition: '0.3s ease',
      },
    },
    '.media-card-content': {
      '.disrupter-wrapper': {
        marginBottom: space[2],
      },
    },
    '&.is-geo': {
      '.teaser-media': {
        maxHeight: '274px',
        '&:before': {
          zIndex: 0,
        },
        '&:after': {
          content: "''",
          display: 'block',
          width: '100%',
          height: '100%',
          background: 'none',
          zIndex: 1,
        },
      },
    },
    '.teaser-media:before': {
      zIndex: 0,
    },
  })
);

export const FdrAllItemsButton = styled(BsButtonWithIcon).attrs({
  variant: 'link-btn',
  icon: { name: 'chevron-long-right', size: 'lg' },
})<{ back?: boolean }>(({ back }) =>
  sx2CssThemeFn({
    display: 'flex',
    color: 'redWeb',
    fontWeight: '500',
    fontSize: '16px',
    position: 'relative',
    py: back ? 6 : '10px',
    paddingTop: '22px',
    alignItems: 'center',
    '.icon': {
      width: '22px',
      height: '22px',
      mr: '10px',
      fill: 'redWeb',
      transform: `rotate(${back ? '180' : '0'}deg)`,
    },
    '&::after': {
      position: 'absolute',
      bottom: back ? '28px' : '6px',
      right: 0,
      display: 'block',
      height: '2px',
      content: '""',
      width: 'calc(100% - 32px)',
      backgroundColor: 'redWeb',
      opacity: 0.2,
      transition: '0.2s ease-in',
    },
    '&::before': {
      position: 'absolute',
      bottom: back ? '28px' : '6px',
      left: '32px',
      display: 'block',
      height: '2px',
      content: '""',
      width: '2px',
      backgroundColor: 'redWeb',
    },

    '&:hover': {
      color: 'redHover',
      '.icon': {
        fill: 'redHover',
      },
      '&::after': {
        opacity: 1,
        backgroundColor: 'redHover',
      },
    },

    '&:active, &:focus': {
      color: 'redActive',
      '.icon': {
        fill: 'redActive',
      },
      '&::after': {
        opacity: 1,
        backgroundColor: 'redActive',
      },
    },
  })
);

export const FdrTeaserDescription = styled.div<{
  imageHeight?: number;
  leadInfoCutLines?: number;
}>(
  ({ imageHeight, leadInfoCutLines }) =>
    sx2CssThemeFn({
      my: '10px',
      display: 'flex',
      height: `calc(100% - ${imageHeight + 20}px)`,
      justifyContent: 'space-between',
      '.icon': {
        alignSelf: 'flex-end',
        color: 'redWeb',
        minWidth: '25px',
      },
      '.description-text': { ...cutTextByLinesCount(3) },
      '.main-text': { ...cutTextByLinesCount(3) },
      '.search-item-breadcrumbs': {
        ...cutTextByLinesCount(2),
        marginBottom: '10px',
        paddingTop: '10px',
        fontSize: '15px',
        fontWeight: '500',
      },
      '.lead-info': { ...cutTextByLinesCount(leadInfoCutLines || 2) },
    }),
  {
    overflow: 'hidden',
    b: {
      fontWeight: '700',
    },
  }
);

export const FdrInnerDescriptionWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
  })
);

export const FdrDescriptionTitle = styled.div<{ mobile: boolean }>(
  ({ mobile }) =>
    sx2CssThemeFn({
      ...cutTextByLinesCount(mobile ? 3 : 2),
      overflow: 'hidden',
      fontSize: '24px',
    })
);

export const FdrTypeFlagsWrapper = styled.div(
  sx2CssThemeFn({
    maxHeight: '42px',
    paddingTop: '10px',
    overflow: 'hidden',
    marginTop: 'auto',
    maxWidth: '420px',
    display: 'flex',
    flexWrap: 'wrap',
  })
);

export const FdrTypeFlag = styled.span(
  sx2CssThemeFn({
    display: 'block',
    marginRight: '8px',
    borderRadius: '5px',
    backgroundColor: 'paperGrey',
    padding: '4px 8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis' as const,
    overflow: 'hidden',
  })
);

export const SearchOpeningStatus = styled(FdrScheduleOpeningStatus)<{
  opened: boolean;
}>(({ opened }) =>
  sx2CssThemeFn({
    ml: '20px',
    mt: 1,
    fontSize: [1, 2],
    fontWeight: [null, 'bold'],
    color: opened ? 'success' : 'redWeb',
    '&:before': {
      width: '10px',
      height: '10px',
      left: -20,
    },
  })
);

export const FdrAllItemsSection = styled.div(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr'],
    gap: '20px',
    mb: 7,
    '.placeholder-section': {
      mt: 4,
    },
  })
);

export const FdrAllItemsSectionHeader = styled.div(
  ({ theme: { text, card } }) => ({
    display: 'flex',
    alignItems: 'center',
    '.title': text.groupHeading,
    '.total-count': card.cardSectionTotalCount,
  }),
  ({ theme: { card } }) => card.cardsSectionHeader
);

export const EmptySearchResult = styled.div(
  sx2CssThemeFn({
    variant: 'text.richText',
    mt: [2, 3],
  })
);

export const FdrAllItemsSkeletonWrapper = styled(FdrCardsSlider)(
  sx2CssThemeFn({
    pt: 0,
    pb: 4,
  })
);
