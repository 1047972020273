import { renderMediaItemPartial } from 'components/domain/default-media-item/default-media-item-render.utils';
import {
  fdrMediaFullSpecs,
  fdrMediaPreviewSpecs,
} from 'components/util/criterias/media.dmc';

export * from 'components/util/criterias/hero.dmc';
export * from 'components/util/criterias/media.dmc';
export * from 'components/util/criterias/productGallery.dmc';
export * from 'components/util/criterias/blog.dmc';
export * from 'components/util/criterias/continet.dmc';
export * from 'components/util/criterias/marketingRecommendation.dmc';

export const renderMedia = renderMediaItemPartial(
  fdrMediaPreviewSpecs,
  fdrMediaFullSpecs
);
