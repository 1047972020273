import styled from 'styled-components';
import { Container } from 'components/domain/container';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import FdrImgWithCopyright from 'components/domain/fdr-copyright/fdr-img-with-copyright';
import { BaseInput } from '@hotelplan/components.common.inputs';
import { FdrTextBlock } from '@hotelplan/fdr.regular.basis.fdr-text-block';

export const HeroLayoutWrapper = styled.div(
  ({ theme, theme: { media, colors, fontSizes, space } }) => ({
    position: 'relative',
    width: '100%',
    ...theme.hero.wrap,
    img: {
      objectPosition: `center`,
    },
    '.hero-wrapper': {
      ...theme.hero.wrap,
      '&:after': {
        background:
          'linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.1) 50%,rgba(0,0,0,0.5) 100%)',
      },
      '.copyright': {
        maxWidth: '1480px',
        margin: 'auto',
        paddingLeft: '20px',
        paddingRight: '20px',
        bottom: '210px',
        textAlign: 'right',
        left: 0,
        right: 0,
        [media.mobile]: {
          paddingLeft: space[3],
          paddingRight: space[3],
          bottom: '10px',
        },
      },
    },
    '.hero-tools': {
      position: 'relative',
      zIndex: 2,
      color: '#000000',
      gap: '10px',
      right: 0,
      [media.mobile]: {
        right: space[3],
      },
      '@media screen and (min-width: 1024px) and (max-width: 1096px)': {
        top: '20px',
      },
      button: {
        marginBottom: '7px',
      },
      '.wishlist-icon': {
        position: 'relative',
        right: 0,
        top: 0,
      },
    },
    '.hero-content': {
      left: '50%',
      transform: 'translateX(-50%)',
      maxWidth: '1480px',
      padding: '0 20px',
      top: '191px',
      zIndex: '1',
      [media.mobile]: {
        padding: 0,
        top: '220px',
      },
      '.hero-tools > .site-tools-button-wrapper, .hero-tools > button': {
        position: 'relative',
        width: '42px',
        height: '42px',
        border: `1px solid ${colors.paperGrey}`,
        borderRadius: '5px',
        backgroundColor: colors.paperGrey,
        '.icon': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '20px',
          height: '20px',
          color: colors.black,
          margin: '0',
          '&.bs-icon-wishlist-active': {
            color: colors.redWeb,
          },
        },
        '.button-text': {
          position: 'absolute',
          top: '53px',
          width: 'max-content',
          '&:first-letter': {
            textTransform: 'capitalize',
          },
        },
        '&:hover': {
          border: `1px solid ${colors.redHover}`,
          '.icon': {
            color: colors.redHover,
          },
          '.button-text': {
            display: 'block',
            color: colors.black,
            fontWeight: '400',
            fontSize: fontSizes[1],
            lineHeight: '22px',
            letterSpacing: '0.5px',
            backgroundColor: colors.paperGrey,
            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            padding: '5px 10px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '10',
            [media.mobile]: {
              right: 0,
              left: 'auto',
              transform: 'none',
            },
            '@media screen and (min-width: 897px) and (max-width: 1700px)': {
              right: 0,
              left: 'auto',
              transform: 'none',
            },
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '16px',
              height: '16px',
              left: '50%',
              top: '-8px',
              backgroundColor: colors.paperGrey,
              transform: 'translateX(-50%)  rotate(-45deg)',
              zIndex: '1',
              [media.mobile]: {
                right: '4px',
                left: 'auto',
              },
              '@media screen and (min-width: 897px) and (max-width: 1700px)': {
                right: '4px',
                left: 'auto',
              },
            },
          },
        },
        '&:active': {
          backgroundColor: colors.white,
          border: `1px solid ${colors.redActive}`,
          '.icon': {
            color: colors.redActive,
          },
        },
      },
    },
  })
);

export const HeroStaticText = styled(Container.withComponent(`section`))(
  ({ theme: { colors, space, media, text } }) => ({
    textShadow: 'none',
    '.home-static-text': {
      color: colors.white,
      position: 'absolute',
      top: '333px',
      textAlign: 'left',
      margin: '0 auto',
      left: '0',
      right: '0',
      width: '100%',
      zIndex: 1,
      variant: 'richText',
      display: 'flex',
      flexDirection: 'column-reverse',
      [media.mobile]: {
        left: 0,
        padding: `0 ${space[3]}`,
        overflow: 'hidden',
        width: '100%',
        top: '322px',
      },
      [media.tablet]: {
        maxWidth: '1480px',
        padding: '0 20px',
      },
      h1: {
        fontSize: '65px',
        lineHeight: '75px',
        fontWeight: '800',
        maxWidth: '60%',
        letterSpacing: '1px',
        [media.mobile]: {
          fontSize: '42px',
          lineHeight: '48px',
          maxWidth: '100%',
          letterSpacing: `unset`,
        },
      },
      '.text-block-text:not(:empty)': {
        ...text.groupHeading,
        marginBottom: space[3],
        maxWidth: '50%',
        fontSize: '15px',
        [media.mobile]: {
          lineHeight: 1.4,
          maxWidth: '100%',
        },
      },
      '&.home-static-text-pdp h1': {
        fontSize: '65px',
        fontWeight: '800',
        lineHeight: '75px',
        maxWidth: '80%',
        letterSpacing: '1px',
        [media.mobile]: {
          fontSize: '42px',
          lineHeight: '48px',
          maxWidth: 'none',
          letterSpacing: `unset`,
        },
      },
    },
  })
);

export const PdpScrollDownWrapper = styled.div({
  position: 'absolute',
  width: '50px',
  height: '100vh',
  margin: '0 auto',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  cursor: 'pointer',
  '&.is-hotel': {
    display: 'none',
  },
  '@media screen and (max-height: 900px)': {
    display: 'none',
  },
});

export const PdpScrollDownButton = styled.div.attrs({
  variant: 'iconBtn',
})(({ theme: { colors } }) => ({
  backgroundColor: colors.white,
  width: '46px',
  height: '46px',
  position: 'absolute',
  bottom: '100px',
  borderRadius: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  '.scroll.icon': {
    position: 'absolute',
    color: colors.primary,
    width: '24px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  ':hover': {
    backgroundColor: colors.primary,
    '.scroll.icon': {
      color: colors.white,
    },
  },
}));

export const HeroImageSkeleton = styled.div(({ theme: { colors } }) => ({
  backgroundColor: colors.lightGreySecond,
  width: '100%',
  height: '100%',
}));

export const HeroImage = styled(FdrImgWithCopyright).attrs({
  lazy: false,
  decoding: `sync`,
  loading: `eager`,
})(
  sx2CssThemeFn({
    objectFit: `cover`,
    width: '100%',
    height: '100%',
  })
);

export const FdrSearchInput = styled(BaseInput)(
  ({ theme }) => theme.inputs.searchInput,
  ({ theme: { media, colors } }) => ({
    width: '100%',
    maxWidth: '912px',
    margin: '0 auto',
    zIndex: 2,
    [media.mobile]: {
      width: '93%',
    },
    '.input-icon': {
      color: colors.primary,
      fill: 'transparent',
      left: '17px',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: 0,
      [media.mobile]: {
        left: '12px',
        width: '24px',
      },
    },
  })
);

const DefaultHeroTextBlock = styled(FdrTextBlock)<{ color?: string }>(
  sx2CssThemeFn(({ color, theme: { text } }) => ({
    color,
    zIndex: '10',
    variant: 'richText',
    display: 'flex',
    flexDirection: 'column-reverse',
    '.text-block-text': {
      ...text.groupHeading,
    },
  }))
);

export const FdrHeroTextBlock = styled(DefaultHeroTextBlock)(
  sx2CssThemeFn(({ theme: { space } }) => ({
    maxWidth: ['100%', '1440px'],
    textAlign: 'left',
    padding: [`0 ${space[3]}`, '0'],
    marginTop: ['60px', '100px'],
    textShadow: 'none',
    overflow: 'hidden',
    '.text-block-title': {
      maxWidth: ['100%', '60%'],
      fontSize: ['42px', '65px'],
      lineHeight: ['48px', '75px'],
      fontWeight: '800',
      letterSpacing: [`unset`, '1px'],
      mb: [4, '40px'],
    },
    '.text-block-text': {
      mb: 3,
      maxWidth: ['100%', '50%'],
      fontSize: '15px',
    },
  }))
);

export const FdrHeroTextBlockHome = styled(DefaultHeroTextBlock)(
  sx2CssThemeFn({
    width: ['93%', '95%'],
    maxWidth: ['460px', '100%'],
    textAlign: 'center',
    margin: '0 auto',
    '.text-block-title': {
      fontSize: ['42px', '65px'],
      lineHeight: ['48px', '75px'],
      fontWeight: '800',
      letterSpacing: [`unset`, '1px'],
      mb: [4, '40px'],
      maxWidth: ['100%', 'none'],
      textShadow: 'none',
    },
    '.text-block-text': {
      mb: [2, 3],
    },
  })
);

export const FdrHeroTextBlockGeo = styled(FdrHeroTextBlockHome)(
  sx2CssThemeFn({
    '.text-block-title': {
      fontSize: ['42px', '65px'],
      lineHeight: ['48px', '75px'],
      fontWeight: '800',
      letterSpacing: [`unset`, '1px'],
      mb: [4, '40px'],
    },
    textAlign: [`left`, 'center'],
    marginTop: ['60px', '100px'],
  })
);
