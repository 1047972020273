import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrLinkOpenMethod, FdrLinkType } from '@hotelplan/supergraph-api';
import {
  FdrProductRecommenderSliderStaticItemButton,
  FdrProductRecommenderSliderStaticItemContent,
  FdrProductRecommenderSliderStaticItemText,
  FdrProductRecommenderSliderStaticItemTitle,
  FdrProductRecommenderSliderStaticItemWrapper,
} from './fdr-product-recommender.styles';

const JOTFORM_LINK_DE = 'https://form.jotform.com/232332781388360';
const JOTFORM_LINK_FR = 'https://form.jotform.com/232332781388360?language=fr';

export function FdrProductRecommenderStaticItem() {
  const [t, { language }] = useTranslation();

  return (
    <FdrProductRecommenderSliderStaticItemWrapper>
      <FdrProductRecommenderSliderStaticItemContent>
        <FdrProductRecommenderSliderStaticItemTitle>
          {t('product.recommender.staticItem.title')}
        </FdrProductRecommenderSliderStaticItemTitle>
        <FdrProductRecommenderSliderStaticItemText>
          {t('product.recommender.staticItem.text')}
        </FdrProductRecommenderSliderStaticItemText>
        <FdrProductRecommenderSliderStaticItemButton>
          <FdrRoute
            link={{
              url: language === 'fr' ? JOTFORM_LINK_FR : JOTFORM_LINK_DE,
              type: FdrLinkType.External,
              openMethod: FdrLinkOpenMethod.Overlay,
            }}
          >
            <BsButton variant="tagBtn">
              {t('product.recommender.staticItem.buttonText')}
            </BsButton>
          </FdrRoute>
        </FdrProductRecommenderSliderStaticItemButton>
      </FdrProductRecommenderSliderStaticItemContent>
    </FdrProductRecommenderSliderStaticItemWrapper>
  );
}
