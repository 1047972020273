import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface ILazyPaginationStaticItem<T> {
  item: T;
  position: number;
}

interface ILazyPaginationOptions<T> {
  perPage: number;
  /**
   * Total count of items across all pages, including the total number of `staticItems`.
   *
   * Note: Ensure that this value accounts for both the dynamically fetched items
   * and the `staticItems` added to the list.
   */
  total: number;
  page?: number;
  /**
   * A collection of static items that should always appear at specific positions
   * in the paginated results.
   */
  staticItems?: ILazyPaginationStaticItem<T>[];
  fetchItems?: (
    page: number,
    perPage: number
  ) => Promise<ILazyPaginationFetchResponse<T>>;
}

interface ILazyPaginationFetchResponse<T> {
  items: T[];
  pagination: {
    page: number;
    perPage: number;
  };
}

function useLazyFetchPagination<T>(
  initialItems: T[],
  options: ILazyPaginationOptions<T>
): {
  items: T[];
  page: number;
  hasMore: boolean;
  nextPage: () => void;
  prevPage: () => void;
  setPage: (page: number) => void;
} {
  const {
    page: initialPage = 0,
    perPage = 3,
    total,
    fetchItems,
    staticItems = [],
  } = options;

  const insertStaticItems = useCallback(
    (prevItems: T[], prevStaticItems: ILazyPaginationStaticItem<T>[]) => {
      const newItems = [...prevItems];

      prevStaticItems.forEach(({ item, position }) => {
        // Ensures the position of the static item is valid, either within the bounds of the existing list or at the end.
        if (position >= 0 && position <= prevItems.length) {
          newItems.splice(position, 0, item);
        }
      });

      return newItems;
    },
    []
  );

  const [page, setPage] = useState<number>(initialPage);
  const [items, setItems] = useState<T[]>(
    insertStaticItems(initialItems, staticItems)
  );
  const prevInitialItemsRef = useRef(initialItems);
  const prevStaticItemsRef = useRef(staticItems);

  const fetchPageItems = useCallback(
    async (targetPage: number) => {
      if (fetchItems && isFetchNeeded(targetPage, perPage, total, items)) {
        const firstNonFetchedItemIndex =
          targetPage * perPage +
          items.slice(targetPage * perPage, (targetPage + 1) * perPage).length;
        const staticItemsNumber = getStaticItemsNumberBeforeIndex(
          staticItems,
          firstNonFetchedItemIndex
        );
        const nonStaticItemsNumber =
          firstNonFetchedItemIndex - staticItemsNumber;
        const backendPage = Math.floor(nonStaticItemsNumber / perPage);

        const result = await fetchItems(backendPage, perPage);

        let newItems = [];

        if (result?.items?.length) {
          newItems = result.items;
        }

        setItems(prevState => {
          const newState = [...prevState];

          newItems.forEach((item, itemIndex) => {
            newState[backendPage * perPage + staticItemsNumber + itemIndex] =
              item;
          });

          return newState;
        });
      }
    },
    [fetchItems, items, perPage, staticItems]
  );

  useEffect(() => {
    if (
      !isEqual(prevInitialItemsRef.current, initialItems) ||
      !isEqual(prevStaticItemsRef.current, staticItems)
    ) {
      prevInitialItemsRef.current = initialItems;
      prevStaticItemsRef.current = staticItems;

      setItems(insertStaticItems(initialItems, staticItems));
      setPage(initialPage);

      fetchPageItems(initialPage);
    }
  }, [initialItems, staticItems]);

  useEffect(() => {
    fetchPageItems(page);
  }, [page]);

  return useMemo(() => {
    const maxPages = Math.ceil(total / perPage) - 1;

    return {
      items,
      page,
      hasMore: page < maxPages,
      nextPage() {
        setPage(prev => (prev < maxPages ? prev + 1 : prev));
      },
      prevPage() {
        setPage(prev => (prev > 0 ? prev - 1 : 0));
      },
      setPage(pageIndex: number) {
        if (pageIndex < 0 || pageIndex > maxPages) {
          return;
        }

        setPage(pageIndex);
      },
    };
  }, [total, perPage, items, page]);
}

function getStaticItemsNumberBeforeIndex<T>(
  staticItems: ILazyPaginationStaticItem<T>[],
  index: number
): number {
  if (!staticItems?.length) return 0;

  return staticItems.filter(({ position }) => position < index).length;
}

function isFetchNeeded<T>(
  targetPage: number,
  perPage: number,
  total: number,
  items: T[]
) {
  const pageItems = items.slice(
    targetPage * perPage,
    (targetPage + 1) * perPage
  );

  return (
    !pageItems.length ||
    (pageItems.length < perPage && items.length < total) ||
    pageItems.some(item => typeof item === 'undefined')
  );
}

export default useLazyFetchPagination;
