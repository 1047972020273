import {
  FdrAdventureTravelRoutePoint,
  FdrDisrupter,
  FdrImage,
  FdrLink,
  FdrProductFeature,
  ProductInfoB2B,
} from '@hotelplan/supergraph-api';

export interface IFdrProductRecommenderProduct {
  id: string;
  name: string;
  link: FdrLink;
  image: FdrImage;
  teaser?: string;
  route?: string[];
  breadcrumbs?: string[];
  productInfoB2b?: ProductInfoB2B;
  googleStaticMapImage?: FdrImage;
  disrupters?: FdrDisrupter[];
  features?: FdrProductFeature[];
  routePoints?: FdrAdventureTravelRoutePoint[];
  bookmarked?: boolean;
}

export interface IFdrProductRecommenderComponent {
  items: IFdrProductRecommenderProduct[];
  name: string;
  total: number;
  backgroundColor?: string;
  fontColor?: string;
}

export enum EFdrProductRecommenderItemType {
  PRODUCT,
  STATIC,
}

export interface IFdrProductRecommenderProductItem {
  type: EFdrProductRecommenderItemType.PRODUCT;
  item: IFdrProductRecommenderProduct;
}

export interface IFdrProductRecommenderStaticItem {
  type: EFdrProductRecommenderItemType.STATIC;
}

export type TFdrRecommenderProductItem =
  | IFdrProductRecommenderProductItem
  | IFdrProductRecommenderStaticItem;
