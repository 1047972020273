import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import FdrProductRecommenderSliderContent from 'components/domain/fdr-product-recommender/fdr-product-recommender-slider-content';
import { RecommendationListContainer } from 'components/domain/recommendations/Recommendations.styles';
import { AgencyRecommenderContentSkeleton } from './fdr-agency-recommender-content-skeleton';

const AGENCIES_PER_PAGE = 3;

export interface IAgencyRecommendationProps {
  total: number;
  page: number;
  prevPage: () => void;
  nextPage: () => void;
  setPage: (page: number) => void;
  title?: string;
  fgColor?: string;
  bgColor?: string;
  customContent?: React.ReactNode;
  imageHeight?: number;
  loading?: boolean;
}

export function FdrAgencyRecommender({
  total,
  customContent,
  ...restProps
}: PropsWithChildren<IAgencyRecommendationProps>): ReactElement {
  const [t] = useTranslation('agency');
  const perPage = AGENCIES_PER_PAGE;

  const {
    fgColor,
    bgColor,
    title,
    loading,
    imageHeight,
    page,
    prevPage,
    nextPage,
    setPage,
    children,
  } = restProps;

  if (loading) {
    return (
      <AgencyRecommenderContentSkeleton
        agencyMapSearch={customContent}
        bgColor={bgColor}
        fgColor={fgColor}
        title={title}
      />
    );
  }

  return (
    <div
      style={{ color: fgColor, backgroundColor: bgColor }}
      data-id="agency-recommendations-section"
    >
      <RecommendationListContainer>
        <FdrProductRecommenderSliderContent
          name={title || t('agency:agencies.title')}
          total={total}
          page={page}
          perPage={perPage}
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={setPage}
          loading={loading}
          fontColor={fgColor}
          customContent={customContent}
          showTotal={false}
          imageHeight={imageHeight}
          isSliderPagination={false}
        >
          {children}
        </FdrProductRecommenderSliderContent>
      </RecommendationListContainer>
      <div></div>
    </div>
  );
}
