import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBookmarkGroupDataQueryVariables = Types.Exact<{
  groupId?: Types.InputMaybe<Types.Scalars['ID']>;
  pagination: Types.FdrPaginationInput;
  imageCriteria?: Types.InputMaybe<
    Array<Types.FdrImageCriteria> | Types.FdrImageCriteria
  >;
}>;

export type FdrBookmarkGroupDataQuery = {
  __typename?: 'Query';
  atBookmarks: {
    __typename?: 'FdrBookmarksResponse';
    shareUrl?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark' }
      | {
          __typename?: 'FdrProductBookmark';
          id: string;
          product?:
            | {
                __typename?: 'FdrAdventureTravel';
                id: string;
                headerImages?: Array<{
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                }> | null;
                googleStaticMapImage?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | { __typename?: 'FdrCruise'; id: string }
            | { __typename?: 'FdrHotel'; id: string }
            | { __typename?: 'FdrRoundTrip'; id: string }
            | null;
        }
      | { __typename?: 'FdrThemeBookmark' }
    > | null;
  };
  hotelBookmarks: {
    __typename?: 'FdrBookmarksResponse';
    shareUrl?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark' }
      | {
          __typename?: 'FdrProductBookmark';
          id: string;
          product?:
            | { __typename?: 'FdrAdventureTravel'; id: string }
            | { __typename?: 'FdrCruise'; id: string }
            | {
                __typename?: 'FdrHotel';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | { __typename?: 'FdrRoundTrip'; id: string }
            | null;
        }
      | { __typename?: 'FdrThemeBookmark' }
    > | null;
  };
  rtBookmarks: {
    __typename?: 'FdrBookmarksResponse';
    shareUrl?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark' }
      | {
          __typename?: 'FdrProductBookmark';
          id: string;
          product?:
            | { __typename?: 'FdrAdventureTravel'; id: string }
            | { __typename?: 'FdrCruise'; id: string }
            | { __typename?: 'FdrHotel'; id: string }
            | {
                __typename?: 'FdrRoundTrip';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | null;
        }
      | { __typename?: 'FdrThemeBookmark' }
    > | null;
  };
  geoBookmarks: {
    __typename?: 'FdrBookmarksResponse';
    shareUrl?: string | null;
    bookmarks?: Array<
      | {
          __typename?: 'FdrGeoBookmark';
          id: string;
          geo?:
            | {
                __typename?: 'FdrContinent';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrCountry';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrCountryGroup';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrDestination';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrRegion';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrResort';
                id: string;
                hero?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | null;
        }
      | { __typename?: 'FdrProductBookmark' }
      | { __typename?: 'FdrThemeBookmark' }
    > | null;
  };
  themeBookmarks: {
    __typename?: 'FdrBookmarksResponse';
    shareUrl?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark' }
      | { __typename?: 'FdrProductBookmark' }
      | {
          __typename?: 'FdrThemeBookmark';
          id: string;
          theme?: {
            __typename?: 'FdrTheme';
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          } | null;
        }
    > | null;
  };
};

export const FdrBookmarkGroupDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrBookmarkGroupData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'imageCriteria' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'FdrImageCriteria' },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '468' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '388' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'mode' },
                    value: { kind: 'EnumValue', value: 'AUTO' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '2x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '936' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '776' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'mode' },
                    value: { kind: 'EnumValue', value: 'AUTO' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '380' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '274' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'mode' },
                    value: { kind: 'EnumValue', value: 'AUTO' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '2x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '760' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '548' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'mode' },
                    value: { kind: 'EnumValue', value: 'AUTO' },
                  },
                ],
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'atBookmarks' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'ADVENTURE_TRAVEL' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shareUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrProductBookmark' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'FdrAdventureTravel',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'headerImages',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fdrImage',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'resized',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'criteria',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'imageCriteria',
                                                      },
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fdrResized',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'googleStaticMapImage',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fdrImage',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'resized',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'criteria',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'imageCriteria',
                                                      },
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fdrResized',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotelBookmarks' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'HOTEL' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shareUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrProductBookmark' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'FdrHotel' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hero' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fdrImage',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'resized',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'criteria',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'imageCriteria',
                                                      },
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fdrResized',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'rtBookmarks' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'ROUND_TRIP' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shareUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrProductBookmark' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'FdrRoundTrip',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hero' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fdrImage',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'resized',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'criteria',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'imageCriteria',
                                                      },
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fdrResized',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'geoBookmarks' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'GEO' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shareUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrGeoBookmark' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hero' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrImage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'resized',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'criteria',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'imageCriteria',
                                                },
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fdrResized',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'themeBookmarks' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'THEME' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shareUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrThemeBookmark' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'theme' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hero' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrImage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'resized',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'criteria',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'imageCriteria',
                                                },
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fdrResized',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrBookmarkGroupDataQuery__
 *
 * To run a query within a React component, call `useFdrBookmarkGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrBookmarkGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrBookmarkGroupDataQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *      imageCriteria: // value for 'imageCriteria'
 *   },
 * });
 */
export function useFdrBookmarkGroupDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrBookmarkGroupDataQuery,
    FdrBookmarkGroupDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrBookmarkGroupDataQuery,
    FdrBookmarkGroupDataQueryVariables
  >(FdrBookmarkGroupDataDocument, options);
}
export function useFdrBookmarkGroupDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrBookmarkGroupDataQuery,
    FdrBookmarkGroupDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrBookmarkGroupDataQuery,
    FdrBookmarkGroupDataQueryVariables
  >(FdrBookmarkGroupDataDocument, options);
}
export type FdrBookmarkGroupDataQueryHookResult = ReturnType<
  typeof useFdrBookmarkGroupDataQuery
>;
export type FdrBookmarkGroupDataLazyQueryHookResult = ReturnType<
  typeof useFdrBookmarkGroupDataLazyQuery
>;
export type FdrBookmarkGroupDataQueryResult = Apollo.QueryResult<
  FdrBookmarkGroupDataQuery,
  FdrBookmarkGroupDataQueryVariables
>;
